import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Duration`}</strong>{`: 5 minutes or 15 minutes `}<br parentName="p"></br>{`
`}<strong parentName="p">{`Slides`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://github.com/hasparus/refinement-types-in-typescript"
      }}>{`https://github.com/hasparus/refinement-types-in-typescript`}</a>{` `}<br parentName="p"></br>{`
`}<strong parentName="p">{`Follow-up post`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://haspar.us/refinement-types/"
      }}>{`https://haspar.us/refinement-types/`}</a>{` `}<br parentName="p"></br>{`
`}<strong parentName="p">{`Original notes`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://paper.dropbox.com/doc/Refinement-Types-in-TypeScript--AtRTxIb1NxWvFpBv3oxBx9jpAg-cZxqOn3c6GkVCONvPTjtE"
      }}>{`Dropbox Paper`}</a></p>
    <h3 {...{
      "id": "pitch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pitch",
        "aria-label": "pitch permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Pitch`}</h3>
    <p>{`I’m gonna explain what refinement types are, geek out about a research paper
that adds them to the TypeScript type system, and live-code a userland
refinement type you can already use in your codebase.`}</p>
    <h3 {...{
      "id": "what-why-and-how",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-why-and-how",
        "aria-label": "what why and how permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`What, Why, and How`}</h3>
    <h4 {...{
      "id": "how",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how",
        "aria-label": "how permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`How?`}</h4>
    <p>{`Lightning talk with VSCode, Quokka and browser as software.`}</p>
    <h4 {...{
      "id": "what",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#what",
        "aria-label": "what permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`What?`}</h4>
    <p>{`Refinement types are easy. Use them to encode more info on the type level.`}</p>
    <h4 {...{
      "id": "why",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#why",
        "aria-label": "why permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Why?`}</h4>
    <p>{`Encoding information on the type level helps you write less bugs.`}</p>
    <h3 {...{
      "id": "outline",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#outline",
        "aria-label": "outline permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Outline`}</h3>
    <ul>
      <li parentName="ul">{`Why and when should I type stronger?`}<ul parentName="li">
          <li parentName="ul">{`JS without JSDoc vs Idris proofs`}</li>
          <li parentName="ul">{`Refinement types are one step further into bulletproof programs.`}</li>
        </ul></li>
      <li parentName="ul">{`Refined TypeScript`}</li>
      <li parentName="ul">{`It’s not rocket science`}</li>
      <li parentName="ul">{`You can do it yourself`}<ul parentName="li">
          <li parentName="ul">{`Live demo`}</li>
        </ul></li>
      <li parentName="ul">{`Libraries with refinements`}</li>
    </ul>
    <hr></hr>
    <p>{`I gave an extended (15 min) version of this talk at `}<a parentName="p" {...{
        "href": "https://www.meetup.com/typescript-krakow/events/266226393/"
      }}>{`Kraków TypeScript User
Group #5`}</a>{`. The slides are on the are on the
`}<a parentName="p" {...{
        "href": "https://github.com/hasparus/refinement-types-in-typescript/tree/extended-15-minutes"
      }}>{`extended-15-minutes`}</a>{`
branch.`}</p>
    <p>{`You can find the video `}<a parentName="p" {...{
        "href": "https://youtu.be/4ccCeAbwmbU"
      }}>{`here`}</a>{`. The quality is
pretty bad, though.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      